@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "PP Nikkei Maru";
  font-style: normal;
  font-weight: 500;
  src: url(./PPNikkeiMaru-Regular.woff2) format("woff2");
}

:host, body, html {
  text-rendering: optimizeLegibility;
  font-synthesis: none;
  font-feature-settings: "ss02","rlig";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #EBE3DA;
}

#colorbar-r {
  background-color: rgb(221, 55, 73);
  background-color: color(display-p3 0.87 0.152 0.23);
}

#colorbar-g {
  background-color: rgb(100, 183, 63);
  background-color: color(display-p3 0.351 0.735 0.18);
}

#colorbar-b {
  background-color: rgb(58, 128, 184);
  background-color: color(display-p3 0.182 0.478 0.715);
}
